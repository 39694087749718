import { FC } from "react"
import styled, { ThemeProvider } from "styled-components"

import { useSite, useTracking, useDictionary } from "@hooks/index"
import { RichText, FooterBottom, Link } from "@components/common"
import { Accordion, CountryToggle } from "@components/ui"

import { ContentfulLink } from "@lib/types"

const invertTheme = (theme: any) => ({
  ...theme,
  color: {
    ...theme.color,
    black: theme.color.white,
    white: theme.color.black,
    greyLight: theme.color.greyDark,
  },
})

export default function Footer(): JSX.Element {
  const dictionary = useDictionary()
  const site = useSite()
  const { track } = useTracking()

  return (
    <StyledFooter>
      <Mobile>
        <ThemeProvider theme={invertTheme}>
          <Accordion
            items={site.footerMenusCollection.items}
            trackingProperties={{ category: "Footer" }}
          />
        </ThemeProvider>
      </Mobile>
      <Container>
        <Grid>
          <ToggleSection>
            <h3>{dictionary.shoppingFrom}:</h3>
            <ToggleContainer>
              <span>{dictionary.youreIn}</span>
              <CountryToggle />
            </ToggleContainer>
          </ToggleSection>
          <Contact>
            <RichText richText={site.footerContact} />
          </Contact>
          <FooterMenus>
            {site.footerMenusCollection.items.map(
              ({
                category,
                linksCollection: { items },
              }: {
                category: string
                linksCollection: any
              }) => {
                return (
                  <FooterMenu key={category}>
                    <FooterMenuHeader>{category}</FooterMenuHeader>
                    <ul>
                      {items.map((link: ContentfulLink) => {
                        return (
                          <FooterMenuItem key={link.sys.id}>
                            <Link
                              link={link}
                              trackingProperties={{ category: "Footer" }}
                            />
                          </FooterMenuItem>
                        )
                      })}
                    </ul>
                  </FooterMenu>
                )
              }
            )}
          </FooterMenus>
          <Text>
            <RichText richText={site.footerText} />
            <Social>
              <SocialItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://facebook.com/hautehijab"
                  onClick={() =>
                    track("Link Clicked", {
                      label: "Facebook",
                      url: "https://facebook.com/hautehijab",
                      category: "Footer",
                    })
                  }
                >
                  <img src="/svg/facebook.svg" loading="lazy" />
                </a>
              </SocialItem>
              <SocialItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/hautehijab"
                  onClick={() =>
                    track("Link Clicked", {
                      label: "Instagram",
                      url: "https://instagram.com/hautehijab",
                      category: "Footer",
                    })
                  }
                >
                  <img src="/svg/instagram.svg" loading="lazy" />
                </a>
              </SocialItem>
              <SocialItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtube.com/hautehijab"
                  onClick={() =>
                    track("Link Clicked", {
                      label: "Youtube",
                      url: "https://youtube.com/hautehijab",
                      category: "Footer",
                    })
                  }
                >
                  <img src="/svg/youtube.svg" loading="lazy" />
                </a>
              </SocialItem>
              <SocialItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pinterest.com/hautehijab"
                  onClick={() =>
                    track("Link Clicked", {
                      label: "Pinterest",
                      url: "https://pinterest.com/hautehijab",
                      category: "Footer",
                    })
                  }
                >
                  <img src="/svg/pinterest.svg" loading="lazy" />
                </a>
              </SocialItem>
            </Social>
          </Text>
        </Grid>
      </Container>
      <Border />
      <Container>
        <FooterBottom
          links={site.footerBottomLinksCollection.items}
          copyright={site.footerCopyright}
        />
      </Container>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.color.greyBlack};
  color: ${({ theme }) => theme.color.white};
`

const Mobile = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const Container = styled.div`
  padding: 2.4rem 2rem 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    max-width: 110rem;
    margin: 0 auto;
    padding: 0 2rem;
  }
`

const Grid = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: grid;
    grid-template-areas:
      "text toggle toggle gap"
      "menus menus contact contact";
    grid-template-columns: 1fr 14.1rem auto 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 4.8rem;
    padding: 7.8rem 0 9.1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    grid-template-areas:
      "text gap menus contact"
      "toggle gap menus contact";
  }
`

const FooterMenus = styled.section`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    grid-area: menus;
  }
`

const FooterMenu = styled.div`
  margin-right: 9.5rem;
`

const FooterMenuHeader = styled.h3`
  font-size: 2rem;
  line-height: 30px;
  margin: 0 0 1.5rem;
`

const FooterMenuItem = styled.li`
  font-size: 1.4rem;
  line-height: 3.8rem;
`

const ToggleSection = styled.section`
  margin: 0 0 2.1rem;

  h3,
  h4 {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 0.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-area: toggle;

    h3,
    h4 {
      margin: 0 0 1.1rem;
    }
  }
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 1.4rem;
    margin-right: 1.4rem;
  }
`

const Contact = styled.section`
  margin-bottom: 1.8rem;
  white-space: pre-wrap;

  h3,
  h4 {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 1.2rem;
  }

  p {
    color: ${({ theme }) => theme.color.grey};
    font-size: 1.4rem;
    line-height: 2.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.color.white};
    line-height: 3.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-area: contact;
  }
`

const Text = styled.section`
  white-space: pre-wrap;

  h3,
  h4 {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 0.7rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-area: text;

    h3,
    h4 {
      margin: 0 0 1.3rem;
    }
  }
`

const Social = styled.ul`
  display: flex;
  align-items: center;
`

const SocialItem = styled.li`
  margin-right: 2.6rem;
`

const Border = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey};
`
